import React from "react"
import { useInView } from "react-intersection-observer"

import { Contact, Restaurant, Shape } from "../components"
import { AnimatedHeader } from "../components/HeaderWithoutNavigation"
import { Layout } from "../components/Layout"
import i18n from "../i18n"
import School from "../components/School"
import SectionContainer from "../components/Layout/SectionContainer"
import Shop from "../components/Shop"
import StaticHero from "../components/Hero/StaticHero"
import Rent from "../components/Rent"
import Prices from "../components/Prices"
import { graphql } from "gatsby"
import { getData } from "../utilities"
import { PrimicHomepage } from "../types"
import ShapePriceSection from "../components/ShapePriceSection/ShapePriceSection"

const lang = "de"
i18n.locale = lang

const Index = ({ data }: { data: PrimicHomepage }) => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  })
  const {
    rental,
    shop,
    shape,
    school,
    rent,
    review,
    tablePrice,
    familyPicture,
    contact,
    restaurant,
    subscribeButton,
    shapePriceOptionTitle,
    shapePriceTitle,
    shapePriceImage,
    shapePrice,
    shapeOptions,
  } = getData(data)

  return (
    <Layout lang={lang}>
      <StaticHero ref={ref} />
      <div>
        <AnimatedHeader inView={inView} />
        <SectionContainer>
          <Shape {...shape} />
          <School {...school} {...review} />
          <Rent {...rent} />
        </SectionContainer>
        <Shop {...shop} />
        <Restaurant {...restaurant} />
        {/* tarifs ecole */}
        <Prices
          tablePrice={tablePrice}
          familyPicture={familyPicture}
          rental={rental}
          buttonLabel={subscribeButton}
        />
        <ShapePriceSection
          shapePriceOptionTitle={shapePriceOptionTitle}
          shapePriceTitle={shapePriceTitle}
          shapePriceImage={shapePriceImage}
          shapePrice={shapePrice}
          shapeOptions={shapeOptions}
        />
        <Contact contact={contact} />
        {/* formulaire contact */}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query pageQueryDE {
    prismicHomepage(lang: { eq: "en-de" }) {
      data {
        section {
          description {
            text
          }
          title {
            text
          }
          image {
            alt
            gatsbyImageData
          }
        }
        review_picture {
          alt
          gatsbyImageData
        }
        review_name {
          text
        }
        review {
          text
        }
        shop_description {
          text
        }
        shop_subsection {
          subsection_description {
            text
          }
          subsection_image {
            alt
            gatsbyImageData
          }
          subsection_title {
            text
          }
        }
        shop_title {
          text
        }
        restaurant_title {
          text
        }
        restaurant_text {
          text
        }
        restaurant_picture {
          gatsbyImageData
          alt
        }
        restaurant_description {
          text
        }
        subscribe_button {
          text
        }
        table_prices {
          column_1 {
            text
          }
          column_2 {
            text
          }
          column_3 {
            text
          }
        }
        picture_under_table_prices {
          alt
          gatsbyImageData
        }
        rental_per_day {
          column_1 {
            text
          }
          column_2 {
            text
          }
          column_3 {
            text
          }
          column_4 {
            text
          }
        }
        rental_per_hour {
          column_1 {
            text
          }
          column_2 {
            text
          }
          column_3 {
            text
          }
          column_4 {
            text
          }
        }
        shape_price_option_title {
          text
        }
        shape_price_title {
          text
        }
        shape_price_image {
          alt
          gatsbyImageData
        }
        shape_price {
          price {
            text
          }
          size {
            text
          }
        }
        shape_options {
          option {
            text
          }
        }
        contact_section {
          address {
            text
          }
          itinary {
            url
          }
          email {
            text
          }
          phone_number {
            text
          }
          phone_number_with_indicatif {
            text
          }
          schedules {
            text
          }
          title {
            text
          }
        }
      }
    }
  }
`

export default Index
